/*
 * @Author: ChenYaJin
 * @Date: 2023-09-07 10:00:08
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 11:47:57
 * @Description: 活动资讯
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse, IOption, Page, TableData } from '~/models/common'
import type { IInfoLevel, IInfo, IActivity, IActivityScore, IScoreDetail, ICommentDetail } from '~/models/activity'
import type { EnterpriseCase } from '~/models/enterprise'

export type IActivityOptions = Page & {
  status?: string
  name?: string
  type?: string
}

export type IInfoOptions = Page & {
  classId1?: string
  classId2?: string
  classId3?: string
  carousel?: number
  pushTimeAtSort?: string
  name?: string
}

/**
 * 资讯分类
 * @returns
 */
export function getInfoLevel() {
  return http.request<BasicResponse<IInfoLevel[]>>({
    url: '/infoClass/open',
    method: 'get',
  })
}
/**
 * 资讯列表
 * http://yapi.wisdomcity.com.cn/project/136/interface/api/22523
 * @returns
 */
export function getInfoList(data: IInfoOptions) {
  return http.request<BasicResponse<TableData<IInfo>>>({
    url: '/info/open',
    method: 'get',
    params: data,
  })
}

/**
 * 资讯详情
 * @returns
 */
export function getInfoDetail(id: string) {
  return http.request<BasicResponse<IInfo>>({
    url: `/info/open/${id}`,
    method: 'get',
  })
}

/**
 * 关于协会
 * @returns
 */
export function getAboutOpen() {
  return http.request<BasicResponse<IInfo>>({
    url: `/singlePage/introductionOfTheAssociation/open`,
    method: 'get',
  })
}

/**
 * 关于协会-企业展示
 * @returns
 */
export function getEnterOpen() {
  return http.request<BasicResponse<EnterpriseCase>>({
    url: `/singlePage/enterpriseShowcase/open`,
    method: 'get',
  })
}

/**
 * 关于协会 - 其他页面
 * @param type 类别（联系我们：contactUs，协会领导：associationLeadership，主要职责：primaryResponsibilities）
 * @returns 列表数据
 */
export function getAboutOtherOpen(type: string) {
  return http.request<BasicResponse<IInfo>>({
    url: `/singlePage/standardPage/open`,
    method: 'get',
    params: {
      type,
    },
  })
}

/**
 * admin: 进行中的活动
 * @returns
 */
export function getActivityList(data: IActivityOptions) {
  return http.request<BasicResponse<TableData<IActivity>>>({
    url: '/activity',
    method: 'get',
    params: data,
  })
}

/**
 * admin: 活动类型枚举
 * @returns
 */
export function getActivityTypeEnum() {
  return http.request<BasicResponse<IOption[]>>({
    url: '/activity/typeEnum',
    method: 'get',
  })
}

/**
 * admin: 活动状态枚举
 * @returns
 */
export function getActivityStatusEnum() {
  return http.request<BasicResponse<IOption[]>>({
    url: '/activity/statusEnum',
    method: 'get',
  })
}

/**
 * admin: 根据活动类型查看可以参与活动的参与方
 * @returns
 */
export function getParticipantsByType(data: { type: string; activityId: string }) {
  return http.request<BasicResponse<IOption[]>>({
    url: '/activity/ByTypeFindBusiness',
    method: 'get',
    params: data,
  })
}

/**
 * admin: 参与活动
 * @returns
 */
export function participateActivity(data: { businessIds: string[]; activityId: string }) {
  return http.request<BasicResponse<number>>({
    url: '/activity/postParticipant',
    method: 'post',
    data: data,
  })
}

/**
 * admin: 根据活动类型和参与方查看活动评分列表
 * @returns
 */
export function getActivityScoreList(data: { enterpriseId: string; businessId: string }) {
  const params = {
    ...data,
    ...{
      isPage: false,
    },
  }
  return http.request<BasicResponse<{ list: IActivityScore[] }>>({
    url: '/activity/participant',
    method: 'get',
    params: params,
  })
}

/**
 * admin: 按活动参与方查看评分详情
 * @returns
 */
export function getActivityScoreDetail(data: { participantId: string }) {
  return http.request<BasicResponse<IScoreDetail>>({
    url: `/activity/standardAverage`,
    method: 'get',
    params: data,
  })
}

/**
 * http://yapi.wisdomcity.com.cn/project/128/interface/api/23488
 * admin: 活动评分评语详情
 * @returns
 */
export function getActivityCommentDetail(data: string) {
  return http.request<BasicResponse<ICommentDetail>>({
    url: `/activity/scoringComment/${data}`,
    method: 'get',
  })
}
